/*
================================================================================
* javascript information
* file name  : utlilty.js (共有のスクリプト)
================================================================================
*/
//スムーズスクロール

//画像ホバー
$(function(){
     $('a img').hover(function(){
        $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
          }, function(){
             if (!$(this).hasClass('currentPage')) {
             $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
        }
   });
});
