
var sub = require( './utility.js' );
var sliderPro = require( "./../../../bower_components/slider-pro/dist/js/jquery.sliderPro.js" );
var cb = require( './libs/css_browser_selector.js' );
var ui = require( './libs/jquery-ui.js' );
/*
================================================================================
* javascript information
* file name  : main.js (トップページ固有のスクリプト)
================================================================================
*/

$(function(){
	$( '#thumb-v' ).sliderPro({
		width: 800,//横幅
		height: 286,
	    orientation: 'vertical',//スライドの方向
	    aspectRatio: 2.85,
	    mageScaleMode: 'exact',
	    arrows: false,//左右の矢印
	    buttons: false,//ナビゲーションボタン
	    loop: false,//ループ
	    thumbnailsPosition: 'right',//サムネイルの位置
	    thumbnailPointer: true,//アクティブなサムネイルにマークを付ける
	    thumbnailWidth: 160,//サムネイルの横幅
	    thumbnailHeight: 92,//サムネイルの縦幅
	    thumbnailArrows:true,
	    
	});
});
$(function(){
	$( '.sp-slide img' ).css({
		width:'800px',
		marginLeft:'0px',
	})
})
$(function(){
	$('.ui-tabs').tabs({show: { opacity: 'toggle', duration: 'slow' }});
});

$(function() {
  var pagetop = $('.pagetop');
       pagetop.click(function () {
           $('html ,body').animate({ scrollTop: 0 }, 'slow');
              return false;
   });
});